<template>
  <div>
    <headGoBack :iCustomizdde="true">
      <template #default>
        <span> 会員手数料について </span>
      </template>
    </headGoBack>
    <div class="conBox">
      <div class="poundBodyMoudle">
        <h1>
          <img
            src="https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202306/649142978cebe.png"
            alt=""
          />
          RAKUMART 会員手数料について
          <img
            src="https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202306/649142978cebe.png"
            alt=""
          />
        </h1>
        <div class="explainHeader">
          <div>
            お客様が当サイトで商品を購入する累計総額に基づいて手数料が設定されます。
            購入総額に応じて手数料が異なりますので、詳細は以下の表をご参照ください。
          </div>
          <div>
            ※手数料の引き下げは、取引金額が一定のレベルを超えた後に適用されます。
            取引金額は配送依頼書の商品が出荷された後に変更します。
          </div>
          <div>
            例えば、実際の取引が1000万円を超えた場合、手数料は4.2%が適用されます。
          </div>
        </div>
      </div>
    </div>
    <div class="conBox">
      <div class="poundBodyMoudle handlingFeeRule">
        <div class="conTitle">透明な手数料で輸入サポート</div>
        <div class="explainFooter">
          <div>
            Rakumartが提供するサービスをご利用いただく上で、シンプルな手数料でお客様の輸入ビジネスをサポート致します。
          </div>
        </div>
        <div class="handlingFeeForm">
          <div class="FeeFormTitle tul">
            <div class="tli FeeFormTitle">{{ $fanyi("会員") }}</div>
            <div class="tli FeeFormTitle">{{ $fanyi("手数料") }}</div>
            <div class="tli FeeFormTitle">代行商品金額合計（万円）</div>
          </div>
          <div
            class="tul feeFormBodyContainer"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="FeeFormBody1 tli">
              <img :src="item.level_icon" />
            </div>
            <div class="FeeFormBody2 tli">
              {{ $fun.ceil(item.service_rate * 100, 3) }}%
            </div>
            <div
              class="FeeFormBody3 tli"
              @click="$fun.routerToPage('/tollVipApplyFor')"
              v-if="index == 0"
            >
              <div>詳細へ</div>
            </div>
            <div v-else class="FeeFormBody3 tli">
              {{ $fun.JPYNumSegmentation(item.consumption) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <footBar />
  </div>
</template>

<script setup>
import { getCurrentInstance, ref } from "vue";
import headGoBack from "@/components/headGoBack";
import footBar from "@/components/footBar/index.vue";
const { proxy } = getCurrentInstance();
//------------------------ data -------------------------------------
const list = ref([]);
//------------------------ methods -------------------------------------
// 获取会员等级信息
const getUserLevelRoot = () => {
  proxy.$api.getUserLevelRoot().then((res) => {
    ////console.log('事件名',res)
    if (res.code != 0) return;
    //接下来的操作
    list.value = res.data;
  });
};
//------------------------ pageLoad -------------------------------------
getUserLevelRoot();

defineExpose({});
</script>
<style lang="scss" scoped>
.hei30 {
  height: 30px;
}
* {
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
}
.conBox {
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;

  .poundBodyMoudle {
    width: 690px;
    padding: 30px;
    margin: 30px auto 0px;
    background-color: white;
    h1 {
      font-size: 28px;
      font-weight: bold;
      height: 64px;
      text-align: center;
      display: flex;
      margin: 30px 0;
      justify-content: center;
      align-items: center;
      img {
        margin: 0 40px;
        width: 19px;
        height: 30px;
      }
    }
    .flexAndCenterAndCenter {
      margin-bottom: 49px;

      div {
        font-weight: bold;
        font-size: 32px;
        line-height: 36px;
        color: #000;
      }
    }

    .explainHeader {
      margin-bottom: 50px;
      line-height: 30px;
      color: #000;
      * {
        font-size: 24px;
        line-height: 36px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 30px;
      }

      > *:nth-child(2) {
        color: #b4272b;
        margin-bottom: 10px;
      }
    }
    .conTitle {
      line-height: 42px;
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .explainFooter {
      margin-bottom: 60px;
      line-height: 36px;
      * {
        font-size: 24px;
        line-height: 36px;
      }
    }
  }

  .handlingFeeRule {
    margin-bottom: 80px;
    padding-top: 60px;
    > * {
      text-align: center;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.8em;
      margin-bottom: 0.9rem;
    }

    .handlingFeeForm {
      border-bottom: 1px solid #f9f9f9;

      .FeeFormTitle {
      }

      .FeeFormBody {
      }

      .tul {
        display: flex;
        align-items: center;

        &.FeeFormTitle {
          background: #f0f0f0;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          overflow: hidden;
        }

        &.FeeFormBody {
          &:nth-child(2n + 1) {
            background-color: #f9f9fb;
          }
        }

        .tli {
          display: flex;
          height: 100%;
          &.FeeFormTitle {
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #000000;
            font-size: 24px;
            flex: 1;
          }

          &.FeeFormTitle:first-child {
          }

          &.FeeFormTitle:nth-child(2) {
          }

          &.FeeFormTitle:last-child {
          }
          &:first-child {
            flex: 0 0 160px;
            background-color: #f3dfbb;
          }

          &:nth-child(2) {
            background-color: #fcf6ed;
          }

          &:last-child {
            flex: 0 0 270px;
            padding-right: 20px;
            background-color: #fcf6ed;
          }
        }
      }

      .feeFormBodyContainer {
        height: 100px;
        width: 100%;
        display: flex;
        .FeeFormBody1,
        .FeeFormBody2 {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .FeeFormBody3 {
          flex: 0 0 310px;
          display: flex;
          justify-content: center;
          align-items: center;

          div {
            width: 121px;
            height: 53px;
            background: #ffffff;
            border: 1px solid #dfdfdf;
            border-radius: 6px;
            font-size: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            color: #b4272b;
          }

          div:active {
            background: #faf2f2;
            border: 1px solid #b4272b;
          }
        }

        img {
          width: 60px;
          height: 60px;
        }
      }

      .feeFormBodyContainer:nth-child(odd) {
        background: #f9f9f9;
      }

      .feeFormBodyContainer:nth-child(even) {
        background: #fff;
      }
    }

    .footerText {
      margin-top: 60px;
      color: #000;

      div:first-child {
        font-weight: bold;
        font-size: 28px;
        margin-bottom: 20px;
      }
      div:last-child {
        font-size: 24px;
        line-height: 36px;
      }
    }
  }
}
</style>
