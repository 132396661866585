<template>
  <div>
    <headGoBack :iCustomizdde="true">
      <template #default>
        <span> </span>
      </template>
    </headGoBack>
    <div class="oheadBox">
      <h1>Rakumartについて</h1>
      <p>
        Rakumartの運営会社となる「申越網絡科技有限公司」は、アリババとタオバオの発祥地である浙江省杭州市にありま
        す。世界最大の日用雑貨卸売り市場を有する、「百円ショップ」の故郷とも呼ばれる同省義烏市に業務母体を構えており
        ます。現時点の従業員総数は260名に達し、営業面積は20000平米を超えています。
      </p>

      <p>
        Rakumartでは、最先端IT技術の開発・導入により、中国における優良サプライヤーと世界中の中小仕入れ業者との繋がりを密接にし、「世界の工場」ならではの優れた商品を世界に流通させ、より透明な情報開示、よりスムーズな業務遂行、より安心、安全、簡単なネット貿易モデルの普及を目標に挙げております。商品リサーチからネット仕入れ、輸出入、OEM、検品、在庫管理、国際輸出、海外倉庫までの一貫した完全サポート体系に頼り、物流、情報流、資金流を一体化とする総合的なB2Bクロスオーバー電子ビジネスの開拓者として前向きに邁進してまいります。
      </p>
    </div>
    <div class="conBox">
      <h1>
        <img
          src="https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202306/649142978cebe.png"
          alt=""
        />
        日本会社
        <img
          src="https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202306/649142978cebe.png"
          alt=""
        />
      </h1>
      <van-image
        lazy-load
        class="jieShaoImg"
        src="https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202306/6491467be7dd5.png"
      >
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
      </van-image>
      <div class="japanName">代表　大舘一夫</div>
      <div class="jieShaoBox">
        <span class="decoration">JAPEN</span>
        <h2>日本本社</h2>
        <div class="IntroductionItem">
          <p class="tit">社名</p>
          <p class="con">RAKUMART株式会社</p>
        </div>
        <div class="IntroductionItem">
          <p class="tit">所在地</p>
          <p class="con">〒556-0011</p>
          <p class="con">
            大阪府大阪市浪速区難波中１丁目３−１８ <br />
            ＤＡＩＩＣＨＩ大阪ビル 8階
          </p>
        </div>
        <div class="IntroductionItem">
          <p class="tit">Tel</p>
          <p class="con">06-7636-8120</p>
        </div>
        <div class="IntroductionItem">
          <p class="tit">メール</p>
          <p class="con">services@rakumart.com</p>
        </div>
      </div>
    </div>
    <footBar />
  </div>
</template>

<script setup>
import { getCurrentInstance, ref } from "vue";
import headGoBack from "@/components/headGoBack";
import footBar from "@/components/footBar/index.vue";
import { optionText } from "@/utlis/option-text";
const { proxy } = getCurrentInstance();
//------------------------ data -------------------------------------

//------------------------ pageLoad -------------------------------------
//------------------------ methods -------------------------------------
defineExpose({});
</script>
<style lang="scss" scoped>
.oheadBox {
  height: 660px;
  padding: 60px 10px 60px 30px;
  background-image: url("https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202306/649138add1842.png");
  background-size: 100% 100%;
  * {
    color: white;
  }
  h1 {
    font-size: 36px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 60px;
    text-align: center;
  }
  p {
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 36px;
  }
}
.conBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
  h1 {
    font-size: 28px;
    font-weight: 600;
    line-height: 30px;
    margin: 35px 0 61px;
    text-align: center;
    img {
      margin: 0 45px;
      width: 19px;
      height: 30px;
    }
  }
  .jieShaoImg {
    width: 360px;
    height: 360px;
    margin: 0 auto 30px;
  }
  .japanName {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 40px;
  }
  .jieShaoBox {
    position: relative;
    width: 510px;
    margin: 0 auto;
    .decoration {
      position: absolute;
      top: 5px;
      left: -55px;
      writing-mode: vertical-rl;
      font-size: 110px;
      line-height: 1;
      font-weight: bold;
      opacity: 0.05;
    }
    h2 {
      font-size: 28px;
      margin-bottom: 60px;
      font-weight: 600;
    }
    .IntroductionItem {
      margin-bottom: 30px;
      p {
        font-size: 24px;
        line-height: 1.2;
      }
      .tit {
        font-weight: bold;
      }
      .con {
        color: #8f8f8f;
      }
    }
  }
}
</style>
